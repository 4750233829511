import React, { useRef, useState, memo } from 'react';
import CategoryChart from '../chart/CategoryChart';
import { Box, Heading, useColorMode, Grid, Tr, Td, Table, Tbody, TableContainer, useBreakpointValue, Link, Text, Button, GridItem } from '@chakra-ui/react';
import './types.css';
import StringMoreDetails from './details/StringMoreDetails';

function StringType({ row_count, variableData, /*showTable, onShowTableToggle*/ }) {
    const { column_name, data_type, categorical } = variableData;

    const [showTable, setShowTable] = useState(false);

    const variableStatistics = variableData.statistics;

    const {
        unique_count,
        unique_ratio,
        null_count,
        sample_size,
    } = variableStatistics;

    let categoricalStatistics = null;

    if (categorical === true) {
        categoricalStatistics = variableStatistics.categorical_count;
    }

    const { colorMode } = useColorMode();
    const bgColor = colorMode === 'dark' ? '#202528' : 'white';

    const fontSize = useBreakpointValue({ base: 'sm', md: 'md', lg: 'md' });

    const parentRef = useRef(null);

    const handleLinkClick = () => {
        const parentElement = document.querySelector('.parent-element'); // Sélectionnez l'élément parent en fonction d'une classe CSS
        if (parentRef.current) {
            parentRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    /*const [showTable, setShowTable] = useState(false);

    const handleShowMoreClick = () => {
      setShowTable(true);
    };*/

    return (
        <Box bg={bgColor} p={4} borderRadius="md" overflowX="auto">
            <Grid
                templateColumns={{ base: '1fr', md: '1fr 1fr' }} // 1 colonne sur mobile, 2 colonnes sur écrans plus larges (à partir de md)
                gap={4}
            >
                <Box p={0} ref={parentRef}>
                    <Link onClick={handleLinkClick} href={`#${column_name}`} textDecoration="none !important" size="lg" className={`variable-name ${colorMode === 'light' ? "variable-name-light" : "variable-name-dark"}`}>
                        {column_name}
                    </Link>
                    <Text opacity={0.6} mb={5}>{data_type + " " + (categorical === true ? 'categorical' : '')}</Text>

                    <TableContainer>
                        <Table variant='simple' className='custom-table' size="sm">
                            <Tbody>
                                <Tr>
                                    <Td fontSize={fontSize}>Distinct</Td>
                                    <Td fontSize={fontSize}>{unique_count}</Td>
                                </Tr>
                                <Tr>
                                    <Td fontSize={fontSize}>Distinct (%)</Td>
                                    <Td fontSize={fontSize}>{
                                        unique_count !== 0 && (unique_ratio * 100) < 0.1 ? (
                                            "< 0.1"
                                        ) : unique_count !== 0 && (unique_ratio * 100) > 99.9 ?
                                            (
                                                ">99.9"
                                            ) :
                                            (
                                                (unique_ratio * 100).toFixed(1)
                                            )
                                    } %</Td>
                                </Tr>
                                <Tr>
                                    <Td fontSize={fontSize}>Missing</Td>
                                    <Td fontSize={fontSize}>{null_count}</Td>
                                </Tr>
                                <Tr>
                                    <Td fontSize={fontSize}>Missing (%)</Td>
                                    <Td fontSize={fontSize}>{
                                        null_count !== 0 && (null_count * 100 / sample_size) < 0.1 ? (
                                            "< 0.1"
                                        ) : null_count !== 0 && (null_count * 100 / sample_size) > 99.9 ?
                                            (
                                                ">99.9"
                                            ) :
                                            (
                                                (null_count * 100 / sample_size).toFixed(1)
                                            )
                                    } %</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box marginTop="auto" p={0} overflowX="auto">
                    <Heading size="md" mb={2}>
                        {categorical === true && <CategoryChart data={categoricalStatistics} />}
                    </Heading>
                </Box>
                <GridItem colSpan={2}>
                    <Button onClick={() => {
                        setShowTable(!showTable); // Inversez l'état local showTable
                        /*onShowTableToggle(column_name);*/
}} >
                        {showTable ? 'Hide details' : 'More details'}
                    </Button>
                    {showTable && (
                        <StringMoreDetails categorical={categorical} data={variableStatistics} row_count={row_count} />
                    )}
                </GridItem>
            </Grid>
        </Box>
    );
}

export default memo(StringType);
