import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import Overview from '../overview/Overview';
import ColumnDetails from '../details/ColumnDetails';
import Sample from '../others/Sample';
import './input.css';

import {
  Heading,
  Box,
  Input,
  Select,
  Checkbox,
  Button,
  Progress,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Flex,
  Tooltip,
  useBreakpointValue,
} from '@chakra-ui/react';
import MissingValues from '../others/MissingValues';

function FileInput() {

  const [file, setFile] = useState(null);
  const [delimiter, setDelimiter] = useState('');
  const [hasHeader, setHasHeader] = useState(true);
  const [result, setResult] = useState(null); // Etat pour stocker le résultat JSON
  const [isLoading, setIsLoading] = useState(false); // État pour suivre le chargement
  const [selectedSheet, setSelectedSheet] = useState(null); // Sélectionnez la première feuille par défaut
  const [isExcelFile, setIsExcelFile] = useState(false); // État pour suivre si le fichier est un fichier Excel

  const fontSize = useBreakpointValue({ base: 'sm', md: 'md', lg: 'md' });

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      const fileType = selectedFile.type;
      if (fileType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        setIsExcelFile(true);
      }
      else {
        setIsExcelFile(false);
      }
    }
  };

  const handleDelimiterChange = (e) => {
    setDelimiter(e.target.value);
  };

  const handleHasHeaderChange = (e) => {
    setHasHeader(e.target.checked);
  };

  const handleUpload = () => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('delimiter', delimiter);
    formData.append('hasHeader', hasHeader);

    // Envoyer le fichier au backend
    axios.post('/api/upload', formData)
      .then((response) => {
        // Stockage du résultat JSON dans l'état local
        setResult(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Erreur lors de l\'envoi du fichier au backend:', error);
        setResult('error');
        setIsLoading(false);
      });

  };

  const handleTabClick = (sheet) => {
    setSelectedSheet(sheet);
  };

  return (
    <Box p="4" minH="1vh" w="100%" margin="0 auto" display="flex" flexDirection="column" alignItems="center" /*className="gradient-bg-welcome"*/>
      <Helmet>
        <title>Profile your data - Vulpes Data</title>
        <meta name="description" content="Profile and analyze your data with Vulpes Data - Gain insights." />
        <meta name="author" content="VulpesData"/>
      </Helmet>
      <Heading as="h1" mb="4">
        Data selection
      </Heading>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Input id="fileInput" type="file" accept=".csv, .xlsx, .xls, .txt" onChange={handleFileChange} display="none" />
        <label htmlFor="fileInput">
          <Button as="span">
            Select a file
          </Button>
        </label>
        <Tooltip hasArrow label='Supported formats : csv, txt, xlsx, xls' placement="right">
          <i className="material-icons centered-link">help</i>
        </Tooltip>
      </div>
      {file && (
        <p>{file.name}</p>
      )}
      <Box mt="4">
        <Select isDisabled={isExcelFile} placeholder='Delimiter' value={delimiter} onChange={handleDelimiterChange}>
          <option value=",">Comma (,)</option>
          <option value=";">Semicolon (;)</option>
          <option value=":">Colon (:)</option>
          <option value="|">Pipe (|)</option>
          <option value="\t">Tabulation</option>
          <option value=" ">Space</option>
        </Select>
      </Box>
      <Box mt="4">
        <Checkbox colorScheme='green' checked={hasHeader} onChange={handleHasHeaderChange}>
          <label>The first line is a header</label>
        </Checkbox>
      </Box>
      <Box mb="10">
        <Button mt="4" onClick={handleUpload} isDisabled={!file || delimiter.trim() === '' && !isExcelFile || isLoading} variant="outline" colorScheme="teal">
          Profile your data
        </Button>
        {isLoading && (
          <Progress mt="5" colorScheme='green' isIndeterminate rounded="full" />
        )}
      </Box>

      {/* Affichez le résultat JSON s'il existe */}
      {result && result != 'error' && result.isExcel === 'false' && (
        <Box w="80%">
          <Tabs variant="enclosed">
            <TabList>
              <Tab fontSize={fontSize}><a href="#tab1">Overview</a></Tab>
              <Tab fontSize={fontSize}><a href="#tab2">Variables</a></Tab>
              <Tab fontSize={fontSize}><a href="#tab3">Sample</a></Tab>
              <Tab fontSize={fontSize}><a href="#tab4">Missing Values</a></Tab>
            </TabList>
            <TabPanels>
              <div id="tab1">
                <Overview data={result} />
              </div>
              <div id="tab2">
                <ColumnDetails data={result} />
              </div>
              <div id="tab3">
                <Sample data={result.first_last_rows} />
              </div>
              <div id="tab4">
                <MissingValues data={result} />
              </div>
            </TabPanels>
          </Tabs>
        </Box>
      )}
      {result && result != 'error' && result.isExcel == null && (
        <Box w="80%">
          <Tabs variant="enclosed">
            <TabList>
              {Object.keys(result).map((sheetName) => (
                <Tab
                  key={sheetName}
                /*onClick={() => handleTabClick(sheet)}
                isSelected={selectedSheet === sheet}*/
                >
                  {sheetName}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              {Object.keys(result).map((sheetName) => (
                <TabPanel key={sheetName}>
                  <Overview data={result[sheetName]} />
                  <ColumnDetails data={result[sheetName]} />
                  <Sample data={result[sheetName].first_last_rows} />
                  <MissingValues data={result[sheetName]} />
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Box>
      )
      }
      {result == "error" && (
        <Flex alignItems="center">
          <Box mr={2}>
            <i className="material-icons centered-link" style={{ fontSize: '3vw', color: "#FC8181" }}>error</i>
          </Box>
          <Text fontSize="2vw" color="#FC8181">Something went wrong ! Please try again</Text>
        </Flex>
      )
      }
    </Box>
  );
}

export default FileInput;
