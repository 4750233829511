import React from 'react';
import { Box, Flex, Image, Text, position, Center, Grid, useMediaQuery } from '@chakra-ui/react';
import Security from "../../assets/images/security.svg";
import AllPeople from "../../assets/images/all_people.svg";
import CustomersReturn from "../../assets/images/listen_customers.svg";
import SaveMoney from "../../assets/images/save_money.svg";
import { Helmet } from 'react-helmet';

const SectionLeft = ({ title, text, imageSrc, altText }) => {
  const [isSmallScreen] = useMediaQuery('(max-width: 900px)');

  return (
    <Center mt="3em">
          <Helmet>
      <title>Immersion - Vulpes Data</title>
      <meta name="description" content="Immerse yourself in Vulpes Data and explore your data with privacy, cost-efficiency, and with solutions tailored to your needs." />
      <meta name="author" content="VulpesData"/>
    </Helmet>
      <Grid
        templateColumns={isSmallScreen ? '1fr' : '1fr 1fr'}
        alignItems="center"
        gap={1}
        maxWidth="80%"
      >
        {isSmallScreen ? (
          <>
            <Box>
              <Image src={imageSrc} alt={altText} boxSize="100%" objectFit="cover" />
            </Box>
            <Box>
              <Text fontSize="3rem" fontWeight="600" mb="3">
                {title}
              </Text>
              <Text>{text}</Text>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <Text fontSize="3rem" fontWeight="600" mb="3">
                {title}
              </Text>
              <Text>{text}</Text>
            </Box>
            <Box justifyContent="center" alignItems="center">
              <Flex flexDirection="column" alignItems="center">
                <Image src={imageSrc} alt={altText} boxSize="50%" objectFit="cover" />
              </Flex>
            </Box>
          </>
        )}
      </Grid>
    </Center>
  );
};

const SectionRight = ({ title, text, imageSrc, altText }) => {
  const [isSmallScreen] = useMediaQuery('(max-width: 900px)');

  return (
    <Center mt="3em">
      <Grid
        templateColumns={isSmallScreen ? '1fr' : '1fr 1fr'}
        alignItems="center"
        gap={1}
        maxWidth="80%"
      >
        {isSmallScreen ? (
          <>
            <Box>
              <Image src={imageSrc} alt={altText} boxSize="100%" objectFit="cover" />
            </Box>
            <Box>
              <Text fontSize="3rem" fontWeight="600" mb="3">
                {title}
              </Text>
              <Text>{text}</Text>
            </Box>
          </>
        ) : (
          <>
            <Box justifyContent="center" alignItems="center">
              <Flex flexDirection="column" alignItems="center">
                <Image src={imageSrc} alt={altText} width="50%" objectFit="cover" />
              </Flex>
            </Box>
            <Box>
              <Text fontSize="3rem" fontWeight="600" mb="3">
                {title}
              </Text>
              <Text>{text}</Text>
            </Box>
          </>
        )}
      </Grid>
    </Center>
  );
};

const Immersion = () => {
  return (
    <div>
      <div>
        <SectionLeft
          title="A solution accessible to all"
          text="Whether you're an expert or a novice, our platform is accessible to all, unleashing the potential of your data in just a few clicks."
          imageSrc={AllPeople}
          altText="text"
        />
      </div>
      <SectionRight
        title="Privacy at the heart of our philosophy"
        text="Your data is safe with us. We do not store or use it for third-party purposes, guaranteeing your confidentiality. Once your data has been processed, we leave no trace of your visit."
        imageSrc={Security}
        altText="text"
      />
      <SectionLeft
        title="Reduce your budget, Free up your resources"
        text="Save on budget and resources with our affordable and efficient alternative, without compromising on quality."
        imageSrc={SaveMoney}
        altText="text"
      />
      <SectionRight
        title="Listening to your needs"
        text="We're constantly evolving to meet your needs, with a roadmap full of innovations to make it easier for you to interact with your data."
        imageSrc={CustomersReturn}
        altText="text"
      />
    </div>
  );
};

export default Immersion;
