import React, { useState, memo, useCallback } from 'react';
import { Box, Heading, useColorMode, Grid, useBreakpointValue, GridItem, Tabs, TabList, Tab, TabPanels, TabPanel} from '@chakra-ui/react';
import NoneType from '../types/NoneType';
import MissingChart from '../chart/MissingChart';

function MissingValues({ data }) {
    const row_count = data.global_stats.row_count;

    const variableData = data.data_stats;
    const [selectedVariable, setSelectedVariable] = useState('');

    // Fonction pour gérer la sélection d'une variable
    const handleVariableChange = useCallback((event) => {
        setSelectedVariable(event.target.value);
    }, []);

    const { colorMode } = useColorMode();
    const bgColor = colorMode === 'dark' ? '#202528' : 'white';

    const fontSize = useBreakpointValue({ base: 'sm', md: 'md', lg: 'md' });

    return (
        <Box>
            <Grid templateColumns="1fr" gap={4} w="100%">
                <GridItem colSpan={2}>
                    <Box mt="10">
                        <Heading as="h2" size="lg" mb={4}>
                            Missing Values
                        </Heading>
                        <Box overflowX="auto" p={4} bgColor={bgColor}>
                            <Tabs variant="enclosed">
                                <TabList>
                                    <Tab fontSize={fontSize}>Count</Tab>
                                    {/*<Tab fontSize={fontSize}>HeatMap</Tab>*/}
                                </TabList>
                                <TabPanels>
                                    <TabPanel>
                                            <MissingChart data={data.data_stats}/>
                                    </TabPanel>

                                    <TabPanel>
                                        <div style={{ flex: 1 }}>

                                        </div>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </Box>
                    </Box>
                </GridItem>
            </Grid>
        </Box>
    );
}

export default memo(MissingValues);
