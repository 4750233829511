import React, { useState, memo, useCallback } from 'react';
import StringType from '../types/StringType';
import NumericType from '../types/NumericType';
import DatetimeType from '../types/DateTimeType';
import DefaultType from '../types/DefaultType';
import { Box, Heading, useColorMode, Grid, Tr, Td, Table, Tbody, TableContainer, useBreakpointValue, Select } from '@chakra-ui/react';
import NoneType from '../types/NoneType';

function ColumnDetails({ data }) {
    const row_count = data.global_stats.row_count;

    const variableData = data.data_stats;
    const [selectedVariable, setSelectedVariable] = useState('');

    // Fonction pour gérer la sélection d'une variable
    const handleVariableChange = useCallback((event) => {
        setSelectedVariable(event.target.value);
    },[]);

    // Filtrer les variables en fonction de la sélection
    const filteredVariables = selectedVariable ? variableData.filter((variable) => variable.column_name === selectedVariable) : variableData;

    const componentMap = {
        string: StringType,
        text: StringType,
        int: NumericType,
        float: NumericType,
        datetime: DatetimeType,
        null: NoneType,
    };

    const { colorMode } = useColorMode();
    const bgColor = colorMode === 'dark' ? '#202528' : 'white';

    const fontSize = useBreakpointValue({ base: 'sm', md: 'md', lg: 'md' });

    // Création un état pour gérer l'affichage du tableau avec plus de détails pour chaque élément
    const [showTables, setShowTables] = useState({});

    const handleShowTableToggle = useCallback((itemId) => {
        // Mettez à jour l'état pour l'élément spécifique
        setShowTables((prevState) => ({
            ...prevState,
            [itemId]: !prevState[itemId], // Inversez la valeur de visibilité
        }));
    },[]);
    return (
        <Box mt="10">
            <Heading as="h2" size="lg" mb={4}>
                Variables
            </Heading>
            <Box mt="4">
                <Select alignSelf="flex-start" w="20%" placeholder='Select variables' value={selectedVariable} onChange={handleVariableChange}>
                    {variableData.map((variable) => (
                        <option key={variable.column_name} value={variable.column_name}>
                            {variable.column_name}
                        </option>
                    ))}
                </Select>
                {filteredVariables.map((variable) => {
                    const ComponentToRender = componentMap[variable.data_type] || DefaultType;

                    return (
                        <Box mb={5} mt={5} className="section-container" key={variable.column_name}>
                            <ComponentToRender row_count={row_count} variableData={variable} /*showTable={showTables[variable.column_name] || false}*/ /*onShowTableToggle={() => handleShowTableToggle(variable.column_name)}*/ />
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
}

export default memo(ColumnDetails);
