import React from 'react';
import { Button, Flex, useColorMode, IconButton, Box, Text, useBreakpointValue, useMediaQuery} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

function MenuBis() {
    const { colorMode, toggleColorMode } = useColorMode();

    const fontSize = useBreakpointValue({ base: 'sm', md: 'md', lg: 'md' });

    // Utilisation de useMediaQuery pour détecter la largeur de l'écran
    const [isSmallScreen] = useMediaQuery('(max-width: 768px)');

    // Texte à afficher en fonction de la taille de l'écran
    const textToShow = isSmallScreen
        ? "Your data is secure! See Immersion section 🤗" // Texte pour les petits écrans
        : "None of your data is stored! It belongs to you 🤗 see Immersion section"; // Texte pour les grands écrans


    return (
        <Flex /*justifyContent="flex-end"*/ alignItems="center">
            <Box flex="1" textAlign="center">
                <Text ml="6">{textToShow}</Text>
            </Box>
            <Box>
                <IconButton
                    icon={
                        colorMode === 'light' ? (
                            <span className="material-icons">dark_mode</span>
                        ) : (
                            <span className="material-icons">light_mode</span>
                        )
                    }
                    onClick={toggleColorMode}
                    aria-label={
                        colorMode === 'light'
                            ? 'Activer le mode sombre'
                            : 'Activer le mode clair'
                    }
                    variant="ghost"
                />
            </Box>
        </Flex>
    );
}

export default MenuBis;
