import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { Box } from '@chakra-ui/react';

const MissingChart = ({ data }) => {
  // État pour stocker les données du graphique
  const [chartData, setChartData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  
  const fetchData = useCallback(() => {

    const labels = data.map((entry) => entry.column_name);
    const nonEmptyData = data.map((entry) => entry.statistics.sample_size - entry.statistics.null_count);
    const emptyData = data.map((entry) => entry.statistics.null_count);

    // Convertir les données du backend en un format compatible avec Chart.js
    const chartData = {
      labels: labels,
      datasets: [

      {
        label: 'Non-Missing',
        backgroundColor: '#76E4F7',
        data: nonEmptyData,
      },
      {
        label: 'Missing',
        backgroundColor: '#FC8181',
        data: emptyData,
      },
      ],
    };
    
    setChartData(chartData);
    setIsLoading(false);
  }, [data]);

  useEffect(() => {
    // Appeler la fonction fetchData ici
    fetchData();
  }, [fetchData]);

  if (isLoading) {
    return null;
  }

  const chartContainerStyle = {
    width: '100%', // Largeur relative par rapport au conteneur parent
    height: '100%', // Hauteur fixe, vous pouvez également l'ajuster relativement
    margin: '0 auto', // Centrage horizontal
  };

  return (
    <Box /*style={chartContainerStyle}*/width={["300%", "200%", "100%"]}  height="500px">
      <Bar
        data={chartData}
        options={{
          scales: {
            x: {
              stacked: true,
              ticks: {
                autoSkip: false,
                maxRotation: 90,
                minRotation: 90,  
              },
            },
            y: {
              beginAtZero: true,
              stacked: true,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        }}
      />
    </Box>
  );
};

export default MissingChart;
