//import './App.css';
import Navbar from './components/Navbar';
import Menu from './components/menu/Menu';
import Input from './components/input/Input';
import { Box, ChakraProvider, Flex, useColorMode, useColorModeValue } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route, Switch } from 'react-router-dom';
import MenuBis from './components/menu/MenuBis';
import Immersion from './components/immersion/Immersion';
import theme from './theme';
import './App.css';
import FeedbackElement from './components/feedback/FeedbackElement';

function App() {
  const { toggleColorMode } = useColorMode()

  const bg = useColorModeValue('#f6f6f9', '#181a1e')

  return (
    <ChakraProvider initialColorMode={theme.config.initialColorMode} theme={theme}>
      <Router>
        <Flex flexDirection="column">
          <Box p="2">
            <MenuBis />
          </Box>
          <Flex>
            <Menu />
            <Box flex="1" ml={{ base: 0, sm: 2, md: 4, lg: 8 }} p="4">
              <Routes>
                <Route path="/" element={<Input />} />
                <Route path="/explore" element={<Input />} />
                <Route path="/immersion" element={<Immersion />} />
              </Routes>
            </Box>
          </Flex>
        </Flex>
      </Router>
      <FeedbackElement />
    </ChakraProvider>
  );
}

export default App;
