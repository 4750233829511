import React from 'react';
import { Box, Tabs, TabList, Tab, TabPanels, TabPanel, Table, Tbody, Tr, Td, Text, useBreakpointValue } from '@chakra-ui/react';
import CategoryChart from '../../chart/CategoryChart';

function NumericMoreDetails({ categorical, data }) {

  const fontSize = useBreakpointValue({ base: 'sm', md: 'md', lg: 'md' });
  
  const quantiles = data.quantiles;

  const quart1 = quantiles[0];
  const quart3 = quantiles[2];

  const {
    min,
    max,
    median,
    mean,
    stddev,
    kurtosis,
    median_abs_deviation,
    skewness,
    sum,
    variance,
    percentile_5th,
    percentile_95th
  } = data

  const range = max - min;
  const cv = stddev / mean;
  const interquart_range = quart3 - quart1;

  return (
    <Box p={4} overflowX="auto">
      <Tabs variant="enclosed">
        <TabList>
          <Tab fontSize={fontSize}>Statistics</Tab>
          <Tab fontSize={fontSize}>Histogram</Tab>
          {/*<Tab>Common values</Tab>
          <Tab>Extreme values</Tab>*/}
        </TabList>
        <TabPanels>
          <TabPanel>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1, marginRight: '1rem' }}>
                <Text fontSize="1.2rem" mt={2}>
                  Quantile statistics
                </Text>
                <Table variant="simple">
                  <Tbody fontSize={fontSize}>
                    <Tr>
                      <Td>Minimum</Td>
                      <Td>{min}</Td>
                    </Tr>
                    <Tr>
                      <Td>5-th percentile</Td>
                      <Td>{(percentile_5th).toFixed(1)}</Td>
                    </Tr>
                    <Tr>
                      <Td>Q1</Td>
                      <Td>{(quart1).toFixed(1)}</Td>
                    </Tr>
                    <Tr>
                      <Td>Median</Td>
                      <Td>{(median).toFixed(1)}</Td>
                    </Tr>
                    <Tr>
                      <Td>Q3</Td>
                      <Td>{(quart3).toFixed(1)}</Td>
                    </Tr>
                    <Tr>
                      <Td>95-th percentile</Td>
                      <Td>{(percentile_95th).toFixed(1)}</Td>
                    </Tr>
                    <Tr>
                      <Td>Maximum</Td>
                      <Td>{max}</Td>
                    </Tr>
                    <Tr>
                      <Td>Range</Td>
                      <Td>{(range).toFixed(1)}</Td>
                    </Tr>
                    <Tr>
                      <Td>Interquartile range (IQR)</Td>
                      <Td>{(interquart_range).toFixed(1)}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>

              <div style={{ flex: 1, marginRight: '1rem' }}>
                <Text fontSize="1.2rem" mt={2}>
                  Descriptive statistics
                </Text>
                <Table variant="simple">
                  <Tbody fontSize={fontSize}>
                  <Tr>
                      <Td>Variance</Td>
                      <Td>{variance}</Td>
                    </Tr>
                    <Tr>
                      <Td>Standard Deviation</Td>
                      <Td>{stddev}</Td>
                    </Tr>
                    <Tr>
                      <Td>Coefficient of Variation (CV)</Td>
                      <Td>{cv}</Td>
                    </Tr>
                    <Tr>
                      <Td>Kurtosis</Td>
                      <Td>{kurtosis}</Td>
                    </Tr>
                    <Tr>
                      <Td>Mean</Td>
                      <Td>{mean}</Td>
                    </Tr>
                    <Tr>
                      <Td>Median Absolute Deviation (MAD)</Td>
                      <Td>{median_abs_deviation}</Td>
                    </Tr>
                    <Tr>
                      <Td>Skewness</Td>
                      <Td>{skewness}</Td>
                    </Tr>
                    <Tr>
                      <Td>Sum</Td>
                      <Td>{sum}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>
            </div>
          </TabPanel>
          {data !== null && (
            <TabPanel>
              <CategoryChart data={data.categorical_count} />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default NumericMoreDetails;
