import React, { memo } from 'react';
import { Box, Tabs, TabList, Tab, TabPanels, TabPanel, Table, Thead, Tbody, Tr, Th, Td, Text, useBreakpointValue } from '@chakra-ui/react';
import CategoryChart from '../../chart/CategoryChart';

function StringMoreDetails({ categorical, data, row_count }) {
  const fontSize = useBreakpointValue({ base: 'sm', md: 'md', lg: 'md' });

  const {
    max,
    median,
    mean,
    min,
    unique_count,
    unique_ratio
  } = data

  let top10;
  let sumValueTop10 = 0;
  let otherValues = false;
  let nbCatRestant = 0;
  if (categorical === true) {
    const categoricalData = data.categorical_count;

    let entries = Object.entries(categoricalData);
    entries.sort((a, b) => b[1] - a[1]);
    const sortedCategoricalData = {};
    entries.forEach(([key, value]) => {
      sortedCategoricalData[key] = value;
    });
    nbCatRestant = entries.length - 10;
    top10 = entries.slice(0, 10);

    for (let i = 0; i < top10.length; i++) {
      sumValueTop10 += top10[i][1]; // Ajouter la valeur actuelle à la somme
    }

    if (sumValueTop10 < row_count) {
      otherValues = true;
    }

  };

  return (
    <Box overflowX="auto" p={4}>
      <Tabs variant="enclosed">
        <TabList>
          <Tab fontSize={fontSize}>Overview</Tab>
          {categorical === true && (
            <>
              <Tab fontSize={fontSize}>Categories</Tab>
              <Tab fontSize={fontSize}>Histogram</Tab>
            </>
          )}
        </TabList>
        <TabPanels>
          <TabPanel>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1, marginRight: '1rem' }}>
                <Text fontSize="1.2rem" mt={2}>
                  Length
                </Text>
                <Table variant="simple">
                  <Tbody fontSize={fontSize}>
                    <Tr>
                      <Td>Max length</Td>
                      <Td>{max}</Td>
                    </Tr>
                    <Tr>
                      <Td>Median length</Td>
                      <Td>{median}</Td>
                    </Tr>
                    <Tr>
                      <Td>Mean length</Td>
                      <Td>{mean}</Td>
                    </Tr>
                    <Tr>
                      <Td>Min length</Td>
                      <Td>{min}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>

              <div style={{ flex: 1, marginRight: '1rem' }}>
                <Text fontSize="1.2rem" mt={2}>
                  Unique
                </Text>
                <Table variant="simple">
                  <Tbody fontSize={fontSize}>
                    <Tr>
                      <Td>Unique</Td>
                      <Td>{unique_count}</Td>
                    </Tr>
                    <Tr>
                      <Td>Unique (%)</Td>
                      <Td>{
                        unique_count !== 0 && (unique_ratio * 100) < 0.1 ? (
                          "< 0.1"
                        ) : unique_count !== 0 && (unique_ratio * 100) > 99.9 ?
                          (
                            ">99.9"
                          ) :
                          (
                            (unique_ratio * 100).toFixed(1)
                          )
                      } %</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>
            </div>
          </TabPanel>
          {categorical === true && (

            <TabPanel>
              <div style={{ flex: 1 }}>
                <Text fontSize="1.2rem" mt={2}>
                  Common values
                </Text>
                <Table variant="simple">
                  <Thead fontSize={fontSize}>
                    <Th>Value</Th>
                    <Th>Count</Th>
                    <Th>Frequency (%)</Th>
                  </Thead>
                  <Tbody fontSize={fontSize}>
                    {top10.map(([key, value]) => (
                      <Tr key={key}>
                        <Td>{key}</Td>
                        <Td>{value}</Td>
                        <Td>{
                          value !== 0 && (value * 100 / row_count) < 0.1 ? (
                            "< 0.1"
                          ) : value !== 0 && (value * 100 / row_count) > 99.9 ?
                            (
                              ">99.9"
                            ) :
                            (
                              ((value / row_count) * 100).toFixed(1)
                            )
                        } %</Td>
                      </Tr>
                    ))}
                    {otherValues === true && (data.null_count !== row_count - sumValueTop10) && (
                      <Tr key="other">
                        <Td>Other values ({nbCatRestant})</Td>
                        <Td>{row_count - sumValueTop10 - data.null_count} </Td>
                        <Td>{
                          (row_count - sumValueTop10 - data.null_count) !== 0 && (((row_count - sumValueTop10 - data.null_count) / row_count) * 100) < 0.1 ? (
                            "< 0.1"
                          ) : (row_count - sumValueTop10 - data.null_count) !== 0 && (((row_count - sumValueTop10 - data.null_count) / row_count) * 100) > 99.9 ?
                            (
                              ">99.9"
                            ) :
                            (
                              (((row_count - sumValueTop10 - data.null_count) / row_count) * 100).toFixed(1)
                            )
                        } %</Td>
                      </Tr>
                    )}
                    {data.null_count > 0 && (
                      <Tr key="null">
                        <Td>(Missing)</Td>
                        <Td>{data.null_count} </Td>
                        <Td>{
                          data.null_count !== 0 && ((data.null_count) / row_count) < 0.1 ? (
                            "< 0.1"
                          ) : data.null_count !== 0 && ((data.null_count) / row_count) > 99.9 ?
                            (
                              ">99.9"
                            ) :
                            (
                              (((data.null_count) / row_count) * 100).toFixed(1)
                            )
                        } %</Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </div>
            </TabPanel>
          )}
          {categorical === true && data != null && (
            <TabPanel>
              <CategoryChart data={data.categorical_count} />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Box>
  );
}


export default memo(StringMoreDetails);
