import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Textarea,
  useColorMode,
  Grid,
  GridItem,
  Input,
  FormControl,
  Heading,
  Text,
  Flex
} from '@chakra-ui/react';
import "./FeedbackElement.css";

function FeedbackElement() {
  const [isOpen, setIsOpen] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [activeModal, setActiveModal] = useState(null);
  const [rating, setRating] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();


  const handleOpenModal = (modal) => {
    setActiveModal(modal);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setActiveModal(null);
    setRating(0);
    setIsSubmitted(false);
    setIsError(false);
    setFeatureRequestFormData({
      email: "",
      feedbackGeneral: "",
      rating: 0,
      bugName: "",
      bugDesc: "",
      featureName: "",
      featureDesc: "",
    });
  };

  const handleBackToMainModal = () => {
    setActiveModal("Main");
    setRating(0);
    setIsSubmitted(false);
    setIsError(false);
    setFeatureRequestFormData({
      email: "",
      feedbackGeneral: "",
      rating: 0,
      bugName: "",
      bugDesc: "",
      featureName: "",
      featureDesc: "",
    });
  };

  const handleFeedbackChange = (e) => {
    setFeedbackText(e.target.value);
  };

  const handleSubmitFeedback = () => {
    // Ajoutez ici la logique pour soumettre le feedback
    // Vous pouvez envoyer le contenu de feedbackText à votre serveur, par exemple.
    // Une fois que le feedback est soumis, vous pouvez fermer la modale.
    setIsOpen(false);
  };

  const [featureRequestFormData, setFeatureRequestFormData] = useState({
    email: "",
    feedbackGeneral: "",
    rating: 0,
    bugName: "",
    bugDesc: "",
    featureName: "",
    featureDesc: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFeatureRequestFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmitFeatureRequest = (e) => {
    e.preventDefault();
    // Envoyez featureRequestFormData au serveur ou effectuez d'autres opérations nécessaires ici
    const formData = new FormData();
    if (activeModal === "General Feedback") {
      formData.append('modal', activeModal);
      formData.append('email', featureRequestFormData.email);
      formData.append('feedbackGeneral', featureRequestFormData.feedbackGeneral);
      formData.append('rating', featureRequestFormData.rating);
    }
    else if (activeModal === "Bug Report") {
      formData.append('modal', activeModal);
      formData.append('email', featureRequestFormData.email);
      formData.append('bugName', featureRequestFormData.bugName);
      formData.append('bugDesc', featureRequestFormData.bugDesc);
    }
    else if (activeModal === "Feature Request") {
      formData.append('modal', activeModal);
      formData.append('email', featureRequestFormData.email);
      formData.append('featureName', featureRequestFormData.featureName);
      formData.append('featureDesc', featureRequestFormData.featureDesc);
    }
    else {
      formData.append('modal', "unknown");
    }

    axios.post('/api/submit-feedback', formData)
      .then((response) => {
        setIsSubmitted(true);
      })
      .catch((error) => {
        console.error('Erreur lors de l\'envoi du fichier au backend:');
        setIsError(true);
      });
  };

  const Star = ({ filled, onClick }) => {
    return (
      <Button
        variant="ghost"
        color={filled ? "#ECC94B" : "gray"}
        onClick={onClick}
      >
        <i className="material-icons centered-link">stars</i>
      </Button>
    );
  };

  const Rating = ({ totalStars, rating, onStarClick }) => {
    return (
      <Flex justifyContent="center" mb={2}>
        {[...Array(totalStars)].map((_, index) => (
          <Star
            key={index}
            filled={index < rating}
            onClick={() => onStarClick(index + 1)}
          />
        ))}
      </Flex>
    );
  };

  const handleStarClick = (newRating) => {
    setRating(newRating);
    featureRequestFormData.rating = newRating;
  };

  return (
    <Box>
      <Button fontSize="14px" className={`custom-button ${colorMode === 'light' ? "light-button-color" : "dark-button-color"}`} position="fixed" top="45%" right="8" transform="translateY(50%) rotate(-90deg)" transformOrigin="top right" zIndex="9999" onClick={() => handleOpenModal("Main")}>Feedback</Button>
      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          {activeModal !== "Main" && (
            <Box float="left" mt="1">
              <Button aria-label="Back to main" onClick={handleBackToMainModal} variant="ghost" _hover={{backgroundColor: "transparent"}}><i className="material-icons">chevron_left</i></Button>
            </Box>
          )}
          <ModalBody mt="1vh" mb="7vh">
            {activeModal === "Main" ? (
              <Grid templateColumns='repeat(1, 3fr)' gap={3} mt="6vh">
                <GridItem>
                  <Button fontWeight="normal" variant="ghost" onClick={() => handleOpenModal("General Feedback")} w="100%" py="10">
                    General Feedback
                  </Button>
                </GridItem>
                <GridItem>
                  <Button fontWeight="normal" variant="ghost" onClick={() => handleOpenModal("Bug Report")} w="100%" py="10">
                    Report a bug
                  </Button>
                </GridItem>
                <GridItem>
                  <Button fontWeight="normal" variant="ghost" onClick={() => handleOpenModal("Feature Request")} w="100%" py="10">
                    Feature request
                  </Button>
                </GridItem>
              </Grid>
            ) : isSubmitted ? (
              <Box>
                <Flex alignItems="center">
                  <Box mr={2}>
                    <i className="material-icons centered-link" style={{ fontSize: '40px', color: "#68D391" }}>check_circle</i>
                  </Box>
                  <Text fontSize="20px" color="#68D391">Request submitted successfully, thank you !</Text>
                </Flex>
              </Box>
            ) : isError ? (
              <Box>
                <Flex alignItems="center">
                  <Box mr={2}>
                    <i className="material-icons centered-link" style={{ fontSize: '40px', color: "#FC8181" }}>error</i>
                  </Box>
                  <Text fontSize="20px" color="#FC8181">Oops, error submitting feedback. We're working on it.</Text>
                </Flex>
              </Box>
            ) :
              (
                <Box>
                  {activeModal === "General Feedback" && (
                    <Box>
                      <Heading fontSize={17} mb={4} textAlign="center">General feedback</Heading>
                      <form onSubmit={handleSubmitFeatureRequest}>
                        <FormControl>
                          <Rating totalStars={5} rating={rating} onStarClick={handleStarClick} name="rating" />
                        </FormControl>
                        <FormControl isRequired mb={2}>
                          <Input type="email" placeholder="Your email address" name="email" value={featureRequestFormData.email} onChange={handleInputChange} />
                        </FormControl>
                        <FormControl isRequired>
                          <Textarea placeholder="Tell us what you think." name="feedbackGeneral" value={featureRequestFormData.feedbackGeneral} onChange={handleInputChange} />
                        </FormControl>

                        <Button type="submit" colorScheme="blue" mt={4}>
                          Submit
                        </Button>
                      </form>
                    </Box>
                  )}
                  {activeModal === "Bug Report" && (
                    <Box>
                      <Heading fontSize={17} mb={4} textAlign="center">Report a bug</Heading>
                      <form onSubmit={handleSubmitFeatureRequest}>
                        <FormControl isRequired mb={2}>
                          <Input type="email" placeholder="Your email address" name="email" value={featureRequestFormData.email} onChange={handleInputChange} />
                        </FormControl>

                        <FormControl isRequired mb={2}>
                          <Input placeholder="Give the bug a name" name="bugName" value={featureRequestFormData.bugName} onChange={handleInputChange} />
                        </FormControl>

                        <FormControl isRequired>
                          <Textarea placeholder="Describe the bug in detail." name="bugDesc" value={featureRequestFormData.bugDesc} onChange={handleInputChange} />
                        </FormControl>

                        <Button type="submit" colorScheme="blue" mt={4}>
                          Submit
                        </Button>
                      </form>
                    </Box>
                  )}
                  {activeModal === "Feature Request" && (
                    <Box>
                      <Heading fontSize={17} mb={4} textAlign="center">Feature request</Heading>
                      <form onSubmit={handleSubmitFeatureRequest}>
                        <FormControl isRequired mb={2}>
                          <Input type="email" placeholder="Your email address" name="email" value={featureRequestFormData.email} onChange={handleInputChange} />
                        </FormControl>

                        <FormControl isRequired mb={2}>
                          <Input placeholder="Give your idea a name" name="featureName" value={featureRequestFormData.featureName} onChange={handleInputChange} />
                        </FormControl>

                        <FormControl isRequired>
                          <Textarea placeholder="Describe your idea." name="featureDesc" value={featureRequestFormData.featureDesc} onChange={handleInputChange} />
                        </FormControl>

                        <Button type="submit" colorScheme="blue" mt={4}>
                          Submit
                        </Button>
                      </form>
                    </Box>
                  )}
                </Box>
              )
            }
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default FeedbackElement;
