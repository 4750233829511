import React, { useRef, useState } from 'react';
import CategoryChart from '../chart/CategoryChart';
import { Box, Heading, useColorMode, Grid, Tr, Td, Table, Tbody, useBreakpointValue, Link, Text, Flex, Button, GridItem } from '@chakra-ui/react';
import './types.css';
import NumericMoreDetails from './details/NumericMoreDetails';

function NumericType({ row_count, variableData, /*showTable, onShowTableToggle*/ }) {
    const { column_name, data_type, categorical } = variableData;

    const [showTable, setShowTable] = useState(false);

    const variableStatistics = variableData.statistics;

    const {
        unique_count,
        unique_ratio,
        null_count,
        sample_size,
        mean,
        min,
        max,
        num_zeros,
        num_negatives,
    } = variableStatistics;

    let categoricalStatistics = null;

    if (categorical === true) {
        categoricalStatistics = variableStatistics.categorical_count;
    }

    const { colorMode } = useColorMode();
    const bgColor = colorMode === 'dark' ? '#202528' : 'white';

    const fontSize = useBreakpointValue({ base: 'sm', md: 'md', lg: 'md' });

    const parentRef = useRef(null);

    const handleLinkClick = () => {
        const parentElement = document.querySelector('.parent-element'); // Sélectionnez l'élément parent en fonction d'une classe CSS
        if (parentRef.current) {
            parentRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Box bg={bgColor} p={4} borderRadius="md" overflowX="auto">
            <Grid
                templateColumns={{ base: '1fr', md: '1fr 1fr' }} // 1 colonne sur mobile, 2 colonnes sur écrans plus larges (à partir de md)
                gap={4}
            >
                <Box p={0} ref={parentRef}>
                    <Link onClick={handleLinkClick} href={`#${column_name}`} textDecoration="none !important" size="lg" className={`variable-name ${colorMode === 'light' ? "variable-name-light" : "variable-name-dark"}`}>
                        {column_name}
                    </Link>
                    <Text opacity={0.6} mb={5}>{data_type + " " + (categorical === true ? 'categorical' : '')}</Text>

                    <Flex flexWrap="wrap">
                        <Table variant='simple' className='custom-table' size="sm" flex={{ base: '100%', sm: '50%' }}>
                            <Tbody>
                                <Tr>
                                    <Td fontSize={fontSize}>Distinct</Td>
                                    <Td fontSize={fontSize}>{unique_count}</Td>
                                </Tr>
                                <Tr>
                                    <Td fontSize={fontSize}>Distinct (%)</Td>
                                    <Td fontSize={fontSize}>{
                                        unique_count !== 0 && (unique_ratio * 100) < 0.1 ? (
                                            "< 0.1"
                                        ) : unique_count !== 0 && (unique_ratio * 100) > 99.9 ?
                                            (
                                                ">99.9"
                                            ) :
                                            (
                                                (unique_ratio * 100).toFixed(1)
                                            )
                                    } %</Td>
                                </Tr>
                                <Tr>
                                    <Td fontSize={fontSize}>Missing</Td>
                                    <Td fontSize={fontSize}>{null_count}</Td>
                                </Tr>
                                <Tr>
                                    <Td fontSize={fontSize}>Missing (%)</Td>
                                    <Td fontSize={fontSize}>{
                                        null_count !== 0 && (null_count * 100 / sample_size) < 0.1 ? (
                                            "< 0.1"
                                        ) : null_count !== 0 && (null_count * 100 / sample_size) > 99.9 ?
                                            (
                                                ">99.9"
                                            ) :
                                            (
                                                (null_count * 100 / sample_size).toFixed(1)
                                            )
                                    } %</Td>
                                </Tr>
                                <Tr>
                                    <Td fontSize={fontSize}>Mean</Td>
                                    <Td fontSize={fontSize}>{mean}</Td>
                                </Tr>
                                <Tr>
                                    <Td fontSize={fontSize}>Minimum</Td>
                                    <Td fontSize={fontSize}>{min}</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                        <Table variant='simple' className='custom-table' size="sm" flex={{ base: '100%', sm: '50%' }}>
                            <Tbody>
                                <Tr>
                                    <Td fontSize={fontSize}>Maximum</Td>
                                    <Td fontSize={fontSize}>{max}</Td>
                                </Tr>
                                <Tr>
                                    <Td fontSize={fontSize}>Zeros</Td>
                                    <Td fontSize={fontSize}>{num_zeros}</Td>
                                </Tr>
                                <Tr>
                                    <Td fontSize={fontSize}>Zeros (%)</Td>
                                    <Td fontSize={fontSize}>{
                                        num_zeros !== 0 && (num_zeros * 100 / sample_size) < 0.1 ? (
                                            "< 0.1"
                                        ) : num_zeros !== 0 && (num_zeros * 100 / sample_size) > 99.9 ?
                                            (
                                                ">99.9"
                                            ) :
                                            (
                                                (num_zeros * 100 / sample_size).toFixed(1)
                                            )
                                    } %</Td>
                                </Tr>
                                <Tr>
                                    <Td fontSize={fontSize}>Negative</Td>
                                    <Td fontSize={fontSize}>{num_negatives}</Td>
                                </Tr>
                                <Tr>
                                    <Td fontSize={fontSize}>Negative (%)</Td>
                                    <Td fontSize={fontSize}>{
                                        num_negatives !== 0 && (num_negatives * 100 / sample_size) < 0.1 ? (
                                            "< 0.1"
                                        ) : num_negatives !== 0 && (num_negatives * 100 / sample_size) > 99.9 ?
                                            (
                                                ">99.9"
                                            ) :
                                            (
                                                (num_negatives * 100 / sample_size).toFixed(1)
                                            )
                                    } %</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Flex>
                </Box>
                <Box marginTop="auto" p={0}>
                    <Heading size="md" mb={2}>
                        {categorical === true && <CategoryChart data={categoricalStatistics} />}
                    </Heading>
                </Box>
                <GridItem colSpan={2}>
                    <Button onClick={() => { setShowTable(!showTable); /*onShowTableToggle(column_name);*/ }} >
                        {showTable ? 'Hide details' : 'More details'}
                    </Button>
                    {showTable && (
                        <NumericMoreDetails categorical={categorical} data={variableStatistics} />
                    )}
                </GridItem>
            </Grid>
        </Box>
    );
}

export default NumericType;
