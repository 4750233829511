import React, { memo } from 'react';
import { Box, Heading, useColorMode, Grid, Th, Thead, Tr, Td, Table, Tbody, useBreakpointValue, Tab, Tabs, TabPanel, TabPanels, TabList, GridItem } from '@chakra-ui/react';


function Sample({ data }) {
    const { colorMode } = useColorMode();
    const bgColor = colorMode === 'dark' ? '#202528' : 'white';

    const fontSize = useBreakpointValue({ base: 'sm', md: 'md', lg: 'md' });

    return (
        <Box>
            <Grid templateColumns="1fr" gap={4} w="100%" overflowX="auto">
                <GridItem colSpan={2}>
                    <Box mt="10">
                        <Heading as="h2" size="lg" mb={4}>
                            Sample
                        </Heading>
                        <Box p={4} bgColor={bgColor} borderRadius="md" overflowX="auto">

                            <Tabs variant="enclosed">
                                <TabList>
                                    <Tab fontSize={fontSize}>First rows</Tab>
                                    <Tab fontSize={fontSize}>Last rows</Tab>
                                </TabList>
                                <TabPanels>
                                    <TabPanel>
                                        <Table variant="simple">
                                            <Thead>
                                                <Tr>
                                                    {data.first_rows.columns.map((columnName, index) => (
                                                        <Th key={index}>{columnName}</Th>
                                                    ))}
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {data.first_rows.data.map((row, rowIndex) => (
                                                    <Tr key={rowIndex}>
                                                        {row.map((cell, cellIndex) => (
                                                            <Td key={cellIndex}>{cell.toString()}</Td>
                                                        ))}
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </TabPanel>

                                    <TabPanel>
                                        <Table variant="simple">
                                            <Thead>
                                                <Tr>
                                                    {data.last_rows.columns.map((columnName, index) => (
                                                        <Th key={index}>{columnName}</Th>
                                                    ))}
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {data.last_rows.data.map((row, rowIndex) => (
                                                    <Tr key={rowIndex}>
                                                        {row.map((cell, cellIndex) => (
                                                            <Td key={cellIndex}>{cell.toString()}</Td>
                                                        ))}
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </Box>
                    </Box>
                </GridItem>
            </Grid>
        </Box>
    );
}

export default memo(Sample);
