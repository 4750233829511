import React from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider, ColorModeScript, Box } from "@chakra-ui/react";
import App from './App';
import theme from './theme';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    //<ChakraProvider>
        //<ColorModeScript initialColorMode={theme.config.initialColorMode} theme={theme}/>
        <App />
    //</ChakraProvider>
);

