// src/Menu.js
import React, { useState } from 'react';
import './Menu.css'
import { Box, VStack, useBreakpointValue, useColorMode, useColorModeValue, Text, Flex, Image } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route, Switch, Link } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons";
import darkLogo from "../../assets/logo/dark_logo.svg";
import lightLogo from "../../assets/logo/light_logo.svg";

function Menu() {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const isSmallScreen = useBreakpointValue({ base: true, md: true, lg: false });
  const isSmall = useMediaQuery({ maxWidth: 640 });

  const { colorMode, toggleColorMode } = useColorMode();

  const bg = useColorModeValue('#fff', '#202528');
  const color = useColorModeValue('#363949', '#fff');

  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);


  return (
    <Flex>
      {isSmall ? (
        <Box
          zIndex="100"
          as="aside"
          h="10px"
          bg={bg}
          color="white"
          position="fixed"
          top="0"
          left="0"
          d="flex"
          flexDir="column"
          alignItems="center">
          <IconContext.Provider value={{ color: "undefined" }}>
            <div className={`navbar ${colorMode === 'light' ? "light-navbar-color" : "dark-navbar-color"}`}>
              <Link to="#" className={`menu-bars ${colorMode === 'light' ? "light-menu-bars-color" : "dark-menu-bars-color"}`}>
                <FaIcons.FaBars onClick={showSidebar} />
              </Link>
            </div>
            <nav className={`nav-menu ${sidebar ? (colorMode === 'light' ? "active light-nav-menu-color" : "active dark-nav-menu-color") : ""}`}>
              <ul className="nav-menu-items" onClick={showSidebar}>
                <li className={`navbar-toggle ${colorMode === 'light' ? "light-navbar-toggle-color" : "dark-navbar-toggle-color"}`}>
                  <Link to="#" className={`menu-bars ${colorMode === 'light' ? "light-menu-bars-color" : "dark-menu-bars-color"}`}>
                    <AiIcons.AiOutlineClose />
                  </Link>
                </li>
                <VStack spacing="4" align="center" justify="center">
                  <Box ml={20}>
                  <Link to="/explore" onClick={() => handleItemClick('Explore')}>
                    {colorMode === "light" ? (
                      <Image src={darkLogo} alt="Vulpes Data Light Logo" maxWidth="45%"/>

                    ) : (
                      <Image src={lightLogo} alt="Vulpes Data Dark Logo" maxWidth="45%"/>
                    )}
                  </Link>
                  </Box>
                  <Link to="/" className={`link ${selectedItem === 'Explore' ? 'selected' : ''} ${colorMode === 'light' ? "dark-link" : "light-link"}`} onClick={() => handleItemClick('Explore')} p="2" color={color}>

                    <i className="material-icons centered-link">analytics</i><Text fontSize="xs">Explore</Text>

                  </Link>
                  <Link to="/immersion" className={`link ${selectedItem === 'Immersion' ? 'selected' : ''} ${colorMode === 'light' ? "dark-link" : "light-link"}`} onClick={() => handleItemClick('Immersion')} p="2" color={color}>

                    <i className="material-icons centered-link">diversity_3</i><Text fontSize="xs">Immersion</Text>

                  </Link>
                  {/*<Link href="#" className={`link ${selectedItem === 'Feedback' ? 'selected' : ''} ${colorMode === 'light' ? "dark-link" : "light-link"}`} onClick={() => handleItemClick('Feedback')} p="2" color={color}>

                    <i className="material-icons centered-link">chat_bubble</i><Text fontSize="xs">Feedback</Text>


      </Link>*/}

                  {/* Ajoutez d'autres options de menu avec des icônes ici */}
                </VStack>
              </ul>
            </nav>
          </IconContext.Provider>
        </Box>
      ) : (
        <Box
          as="aside"
          w={isSmallScreen ? ("55px") : ("100px")}
          h="100vh"
          bg={bg}
          color="white"
          position="fixed"
          top="0"
          left="0"
          p="4"
          d="flex"
          flexDir="column"
          alignItems="center"
        >

          <VStack spacing="4" align="center" justify="center">
            <Link to="/" onClick={() => handleItemClick('Explore')}>
              {colorMode === "light" ? (
                <Image src={darkLogo} alt="Vulpes Data Light Logo" maxWidth="100%"/>
              ) : (
                <Image src={lightLogo} alt="Vulpes Data Dark Logo" maxWidth="100%"/>
              )}
            </Link>
            <Link to="/explore" className={`link ${selectedItem === 'Explore' ? 'selected' : ''} ${colorMode === 'light' ? "dark-link" : "light-link"}`} onClick={() => handleItemClick('Explore')} p="2" color={color}>
              {isSmallScreen ? ( // Vérifiez la taille de l'écran
                <i className="material-icons centered-link">analytics</i>  // Icône
              ) : (
                <>
                  <i className="material-icons centered-link">analytics</i> <Text fontSize="xs">Explore</Text>
                </>
              )}
            </Link>
            <Link to="/immersion" className={`link ${selectedItem === 'Immersion' ? 'selected' : ''} ${colorMode === 'light' ? "dark-link" : "light-link"}`} onClick={() => handleItemClick('Immersion')} p="2" color={color}>
              {isSmallScreen ? ( // Vérifiez la taille de l'écran
                <i className="material-icons centered-link">diversity_3</i>   // Icône
              ) : (
                <>
                  <i className="material-icons centered-link">diversity_3</i> <Text fontSize="xs">Immersion</Text>
                </>
              )}
            </Link>
            {/*<Link href="#" className={`link ${selectedItem === 'Feedback' ? 'selected' : ''} ${colorMode === 'light' ? "dark-link" : "light-link"}`} onClick={() => handleItemClick('Feedback')} p="2" color={color}>
              {isSmallScreen ? ( // Vérifiez la taille de l'écran
                <i className="material-icons centered-link">chat_bubble</i>   // Icône
              ) : (
                <>
                  <i className="material-icons centered-link">chat_bubble</i> <Text fontSize="xs">Feedback</Text>
                </>
              )}

              </Link>*/}

            {/* Ajoutez d'autres options de menu avec des icônes ici */}
          </VStack>
        </Box>
      )
      }
    </Flex>
  );
}

export default Menu;
