import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { Box } from '@chakra-ui/react';

const CategoryChart = ({ data }) => {
  // État pour stocker les données du graphique
  const [chartData, setChartData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  
  const fetchData = useCallback(() => {
    // Convertir l'objet JSON en un tableau de clés et de valeurs
    const keys = Object.keys(data);
    const values = Object.values(data);

    // Convertir les données du backend en un format compatible avec Chart.js
    const chartData = {
      labels: keys,
      datasets: [
        {
          label: 'Fréquence',
          data: values,
          backgroundColor: '#76E4F7',

        },
      ],
    };
    
    setChartData(chartData);
    setIsLoading(false);
  }, [data]);

  useEffect(() => {
    // Appeler la fonction fetchData ici
    fetchData();
  }, [fetchData]);

  if (isLoading) {
    return null;
  }

  const chartContainerStyle = {
    width: '80%', // Largeur relative par rapport au conteneur parent
    height: '100%', // Hauteur fixe, vous pouvez également l'ajuster relativement
    margin: '0 auto', // Centrage horizontal
  };

  return (
    <Box style={chartContainerStyle} /*width={["300%", "100%", "100%"]}  height="400px"*/>
      <Bar
        data={chartData}
        options={{
          scales: {
            x: {
              ticks: {
                maxRotation: 0, // Désactive la rotation des étiquettes
                maxTicksLimit: 10, // Limite le nombre maximal d'étiquettes affichées
              },
              /*beforeUpdate(axis) {
                const labels = axis.chart.data.labels;
                for (let i=0; i<labels.length; i++) {
                  const lbl = labels[i];
                  if (typeof lbl === 'string' && lbl.length > 10) {
                    labels[i] = lbl.substring(0, 10); // cutting
                  }
                }
              }*/
            },
            y: {
              beginAtZero: true,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        }}
      />
    </Box>
  );
};

export default CategoryChart;
