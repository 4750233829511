// 1. import `extendTheme` function
import { extendTheme } from '@chakra-ui/react'

// 2. Add your color mode config
const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

// 3. extend the theme
const theme = extendTheme({
  config,
  styles: {
    global: (props) => ({
      body: {
        bg: props.colorMode === 'dark' ? '#181a1e' : '#f6f6f9',
        minHeight: '100vh', // Hauteur minimale de l'élément body (100% de la hauteur de la vue) 
        display: 'flex',
        flexDirection: 'column', // Permet au contenu de pousser vers le bas
      },
    }),
  },
})

export default theme