import React from 'react';
import './overview.css';
import { Box, Heading, useColorMode, Grid, Tr, Td, Table, Tbody, TableContainer, useBreakpointValue } from '@chakra-ui/react';

function Overview({ data }) {

    const globalStats = data.global_stats;

    //Accès à l'objet types_occu
    const typesOccu = globalStats.types_occu;

    // Extraction des données nécessaires de types_occu
    const {
        int,
        string,
        datetime,
        float,
        NoneType,
    } = typesOccu;

    //Extraction des données nécessaires à partir de global_stats
    const {
        column_count,
        row_count,
        samples_used,
        sum_null,
        duplicate_row_count
    } = globalStats;

    const { colorMode } = useColorMode();
    const bgColor = colorMode === 'dark' ? '#202528' : 'white';

    const fontSize = useBreakpointValue({ base: 'sm', md: 'md', lg: 'md' });


    return (
        <>
            <Heading mt="5" as="h2" size="lg" mb={2}>
                Overview
            </Heading>
            <Box bg={bgColor} overflowX="auto" borderRadius="md">

                <Box bg={bgColor} p={4} borderRadius="md">
                    <Grid
                    templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={4} w="100%" overflowX="auto"
                    >
                        <Box p={0}>
                            <Heading size="md" mb={2}>
                                Dataset statistics
                            </Heading>
                            <TableContainer>
                                <Table variant='simple' className='custom-table' size="sm">
                                    <Tbody>
                                        <Tr>
                                            <Td fontSize={fontSize}>Number of variables</Td>
                                            <Td fontSize={fontSize}>{column_count}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td fontSize={fontSize}>Number of observations</Td>
                                            <Td fontSize={fontSize}>{row_count}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td fontSize={fontSize}>Missing cells</Td>
                                            <Td fontSize={fontSize}>{sum_null}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td fontSize={fontSize}>Missing cells (%)</Td>
                                            <Td fontSize={fontSize}>{
                                                sum_null != 0 && (sum_null * 100 / (column_count * row_count)) < 0.1 ? (
                                                    "< 0.1"
                                                ) :
                                                    (
                                                        (sum_null * 100 / (column_count * row_count)).toFixed(1)
                                                    )
                                            } %</Td>
                                        </Tr>
                                        <Tr>
                                            <Td fontSize={fontSize}>Duplicate Rows</Td>
                                            <Td fontSize={fontSize}>{duplicate_row_count}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td fontSize={fontSize}>Duplicate Rows (%)</Td>
                                            <Td fontSize={fontSize}>{
                                                duplicate_row_count != 0 && (duplicate_row_count * 100 / row_count) < 0.1 ? (
                                                    "< 0.1"
                                                ) :
                                                    (
                                                        (duplicate_row_count * 100 / row_count).toFixed(1)
                                                    )
                                            } %</Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Box>
                        <Box p={0}>
                            <Heading size="md" mb={2}>
                                Variable types
                            </Heading>
                            <TableContainer>
                                <Table variant='simple' className='custom-table' size="sm">
                                    <Tbody>
                                        <Tr>
                                            <Td fontSize={fontSize}>Number of numeric variables</Td>
                                            <Td fontSize={fontSize}>{int !== undefined && float !== undefined ? int + float : int === undefined && float !== undefined ? float : int !== undefined && float === undefined ? int : ''}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td fontSize={fontSize}>Number of string variables</Td>
                                            <Td fontSize={fontSize}>{string !== undefined ? string : ''}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td fontSize={fontSize}>Number of datetime variables</Td>
                                            <Td fontSize={fontSize}>{datetime !== undefined ? datetime : ''}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td fontSize={fontSize}>NoneType</Td>
                                            <Td fontSize={fontSize}>{NoneType !== undefined ? NoneType : ''}</Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Grid>
                </Box>
            </Box>
        </>
    );
}

export default Overview;
