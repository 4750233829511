import React from 'react';
import { Box, Tabs, TabList, Tab, TabPanels, TabPanel, Table, Thead, Tbody, Tr, Th, Td, Heading, Text, useBreakpointValue } from '@chakra-ui/react';
import CategoryChart from '../../chart/CategoryChart';

function DateTimeMoreDetails({ categorical, data }) {
  const fontSize = useBreakpointValue({ base: 'sm', md: 'md', lg: 'md' });

  return (
    <Box p={4} overflowX="auto">
      <Tabs variant="enclosed">
        <TabList>
          <Tab>Histogram</Tab>
        </TabList>
        <TabPanels>
          {data !== null && (
            <TabPanel>
              <CategoryChart data={data} />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default DateTimeMoreDetails;
